import { graphql, Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ImageBanner from "../components/ImageBanner"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const Operations = ({ data }) => {
  const { strapiOperationPage: info } = data

  const path1 = getImage(info?.operations1_image?.localFile)
  const path2 = getImage(info?.operations2_image?.localFile)
  const path3 = getImage(info?.operations3_image?.localFile)

  return (
    <Layout>
      <Seo Sitetitle="Operations" />
      <ImageBanner heading={info.banner_heading} img={info.banner_image.url} />
      <Container>
        <Row className="justify-content-center align-items-center my-4 my-lg-5">
          <Col lg={6}>
            <h1 className="my-3">{info.operations1_heading}</h1>
            <p>{info.operations1_description}</p>
            <Link to={info.operations1_link}>
              <button className="btn btn-yellow">View More</button>
            </Link>
          </Col>
          <Col lg={6}>
            <GatsbyImage image={path1} alt="image1" className="operation-img" />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center align-items-center my-4 my-lg-5">
          <Col lg={6} className="order-2 order-lg-1">
            <GatsbyImage image={path2} alt="image1" className="operation-img" />
          </Col>
          <Col lg={6} className="order-1 order-lg-2">
            <h1 className="my-3">{info.operations2_heading}</h1>
            <p>{info.operations2_description}</p>
            <Link to={info.operations2_link}>
              <button className="btn btn-yellow">View More</button>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center align-items-center my-4 my-lg-5">
          <Col lg={6}>
            <h1 className="my-3">{info.operations3_heading}</h1>
            <p>{info.operations3_description}</p>
            <Link to={info.operations3_link}>
              <button className="btn btn-yellow">View More</button>
            </Link>
          </Col>
          <Col lg={6}>
            <GatsbyImage image={path3} alt="image1" className="operation-img" />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const data = graphql`
  {
    strapiOperationPage {
      banner_heading
      banner_image {
        url
      }
      operations1_heading
      operations1_description
      operations1_link
      operations1_image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
      }
      operations2_heading
      operations2_description
      operations2_link
      operations2_image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
      }
      operations3_heading
      operations3_description
      operations3_link
      operations3_image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
      }
    }
  }
`

export default Operations
